import api from '../api';

function findAll() {
  return new Promise((resolve, reject) => {
    return api.get(`/exchange-banks/types`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}


export default {
  findAll,
};