'use strict';
(function () {
    (function () {
        // eslint-disable-next-line no-undef
        tinymce.IconManager.add('material', {
            icons: {
              'accessibility-check': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 2a2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2c0-1.1.9-2 2-2zm9 7h-6v13h-2v-6h-2v6H9V9H3V7h18v2z"/></svg>',
              'align-center': '<svg width="24" height="24"><path d="M7 17v2h10v-2H7zm0-8v2h10V9H7zm-2 6h14v-2H5v2zM5 5v2h14V5H5z" fill-rule="nonzero"/></svg>',
              'align-justify': '<svg width="24" height="24"><path fill-rule="nonzero" d="M5 19h14v-2H5v2zm0-4h14v-2H5v2zM5 5v2h14V5H5zm0 6h14V9H5v2z"/></svg>',
              'align-left': '<svg width="24" height="24"><path fill-rule="nonzero" d="M5 19h10v-2H5v2zM15 9H5v2h10V9zM5 5v2h14V5H5zm0 10h14v-2H5v2z"/></svg>',
              'align-none': '<svg width="24" height="24"><path fill-rule="nonzero" d="M7.7 17l-1.1 2H5v-2h2.7zm3.4 0H19v2h-9l1-2zm-1.3-4l-1 2H5v-2h4.8zm3.4 0H19v2h-6.9l1-2zm.8-8l-1 2H5V5h9zm3.4 0H19v2h-2.7l1.1-2zm-5.5 4l-1 2H5V9h6.9zm3.4 0H19v2h-4.8l1-2zm.9-6.5l1.3.7-9.7 18.3-1.3-.7 9.7-18.3z"/></svg>',
              'align-right': '<svg width="24" height="24"><path fill-rule="nonzero" d="M9 19h10v-2H9v2zm-4-4h14v-2H5v2zM5 5v2h14V5H5zm4 6h10V9H9v2z"/></svg>',
              'arrow-left': '<svg width="24" height="24"><path fill-rule="nonzero" d="M5.6 13l12 6a1 1 0 0 0 1.4-1V6a1 1 0 0 0-1.4-.9l-12 6a1 1 0 0 0 0 1.8z"/></svg>',
              'arrow-right': '<svg width="24" height="24"><path fill-rule="nonzero" d="M18.5 13l-12 6A1 1 0 0 1 5 18V6a1 1 0 0 1 1.5-.9l12 6a1 1 0 0 1 0 1.8z"/></svg>',
              'bold': '<svg width="24" height="24"><path fill-rule="nonzero" d="M14.6 11.8c.9-.6 1.4-1.4 1.4-2.3 0-2-1.6-3.5-3.5-3.5H7v12h6.3c1.7 0 3.2-1.5 3.2-3.3 0-1.3-.8-2.4-1.9-2.9zM9.5 8h2.8a1.5 1.5 0 1 1 0 3H9.4V8zm3.3 8H9.4v-3h3.3a1.5 1.5 0 1 1 0 3z"/></svg>',
              'bookmark': '<svg width="24" height="24"><path fill-rule="nonzero" d="M16.3 4H7.7C6.7 4 6 4.8 6 5.8V20l6-2.7 6 2.7V5.8c0-1-.8-1.8-1.7-1.8z"/></svg>',
              'border-width': '<svg width="24" height="24"><path fill-rule="nonzero" d="M3 17h18v-2H3v2zm0 3h18v-1H3v1zm0-7h18v-3H3v3zm0-9v4h18V4H3z"/></svg>',
              'brightness': '<svg width="24" height="24"><path fill-rule="nonzero" d="M20 15.3l3.3-3.3L20 8.7V4h-4.7L12 .7 8.7 4H4v4.7L.7 12 4 15.3V20h4.7l3.3 3.3 3.3-3.3H20v-4.7zM12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12z"/></svg>',
              'browse': '<svg width="24" height="24"><path fill-rule="nonzero" d="M19 4H5a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h4v-2H5V8h14v10h-4v2h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"/></svg>',
              'cancel': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 4.6a7.4 7.4 0 1 1 0 14.8 7.4 7.4 0 0 1 0-14.8zM12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18zm0 8L14.8 8l1 1.1-2.7 2.8 2.7 2.7-1.1 1.1-2.7-2.7-2.7 2.7-1-1.1 2.6-2.7-2.7-2.7 1-1.1 2.8 2.7z"/></svg>',
              'change-case': '<svg width="24" height="24"><path fill-rule="nonzero" d="M19 18a4 4 0 0 1-.1-.9c-.7.7-1.5 1-2.4 1a3 3 0 0 1-2.1-.7c-.5-.4-.8-1-.8-1.7 0-1 .3-1.6 1-2 .7-.6 1.6-.8 2.8-.8h1.4v-.7c0-.4-.1-.8-.4-1.1-.3-.3-.8-.5-1.3-.5-.6 0-1 .2-1.4.4-.3.3-.5.5-.5.9h-1.4c0-.4.1-.8.4-1.3a3 3 0 0 1 1.2-.9c.5-.2 1.1-.3 1.7-.3 1 0 1.8.2 2.3.7.6.5.9 1.2.9 2V16c0 .8 0 1.4.3 1.9v.1H19zm-2.3-1.1l1.3-.3c.4-.3.6-.5.8-.9v-1.5h-1.1c-1.8 0-2.7.4-2.7 1.4 0 .4.2.7.5 1 .3.2.7.3 1.2.3zM6.5 13.7h4l-2-5.4-2 5.4zM7.6 6h1.8L14 18h-2l-1-2.6H5.9l-1 2.6H3L7.6 6z"/></svg>',
              'character-count': '<svg width="24" height="24"><path d="M4 11.5h16v1H4v-1zm4.8-6.8V10H7.7V5.8h-1v-1h2zM11 8.3V9h2v1h-3V7.7l2-1v-.9h-2v-1h3v2.4l-2 1zm6.3-3.4V10h-3.1V9h2.1V8h-2.1V6.8h2.1v-1h-2.1v-1h3.1zM5.8 16.4c0-.5.2-.8.5-1 .2-.2.6-.3 1.2-.3l.8.1c.2 0 .4.2.5.3l.4.4v2.8l.2.3H8.2v-.1-.2l-.6.3H7c-.4 0-.7 0-1-.2a1 1 0 0 1-.3-.9c0-.3 0-.6.3-.8.3-.2.7-.4 1.2-.4l.6-.2h.3v-.2l-.1-.2a.8.8 0 0 0-.5-.1 1 1 0 0 0-.4 0l-.3.4h-1zm2.3.8h-.2l-.2.1-.4.1a1 1 0 0 0-.4.2l-.2.2.1.3.5.1h.4l.4-.4v-.6zm2-3.4h1.2v1.7l.5-.3h.5c.5 0 .9.1 1.2.5.3.4.5.8.5 1.4 0 .6-.2 1.1-.5 1.5-.3.4-.7.6-1.3.6l-.6-.1-.4-.4v.4h-1.1v-5.4zm1.1 3.3c0 .3 0 .6.2.8a.7.7 0 0 0 1.2 0l.2-.8c0-.4 0-.6-.2-.8a.7.7 0 0 0-.6-.3l-.6.3-.2.8zm6.1-.5c0-.2 0-.3-.2-.4a.8.8 0 0 0-.5-.2c-.3 0-.5.1-.6.3l-.2.9c0 .3 0 .6.2.8.1.2.3.3.6.3.2 0 .4 0 .5-.2l.2-.4h1.1c0 .5-.3.8-.6 1.1a2 2 0 0 1-1.3.4c-.5 0-1-.2-1.3-.6a2 2 0 0 1-.5-1.4c0-.6.1-1.1.5-1.5.3-.4.8-.5 1.4-.5.5 0 1 0 1.2.3.4.3.5.7.5 1.2h-1v-.1z" fill-rule="evenodd"/></svg>',
              'checklist': '<svg width="24" height="24"><path fill-rule="nonzero" d="M10 19h11v-2H10v2zm0-6h11v-2H10v2zm0-8h11v2H10V5zM8 15.3l1 .9-4.3 4L2 17.7l1-.8 1.7 1.7 3.4-3.2zM8 9l1 .9L4.7 14 2 11.4l1-1 1.7 1.8L8.1 9zm0-6l1 .9L4.7 8 2 5.4l1-1 1.7 1.8L8.1 3z"/></svg>',
              'checkmark': '<svg width="24" height="24"><path fill-rule="nonzero" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z"/></svg>',
              'chevron-down': '<svg width="10" height="10"><path fill-rule="nonzero" d="M9.5 3.5l-1-1L5 5.8 1.6 2.4.5 3.5 5 8z"/></svg>',
              'chevron-left': '<svg width="10" height="10"><path fill-rule="nonzero" d="M6.5 9.5l1-1L4.2 5l3.5-3.4L6.5.5 2 5z"/></svg>',
              'chevron-right': '<svg width="10" height="10"><path fill-rule="nonzero" d="M3.5.5l-1 1L5.8 5 2.4 8.4l1.1 1.1L8 5z"/></svg>',
              'chevron-up': '<svg width="10" height="10"><path fill-rule="nonzero" d="M.5 6.5l1 1L5 4.2l3.4 3.5 1.1-1.1L5 2z"/></svg>',
              'close': '<svg width="24" height="24"><path fill-rule="nonzero" d="M19 6.4L17.6 5 12 10.6 6.4 5 5 6.4l5.6 5.6L5 17.6 6.4 19l5.6-5.6 5.6 5.6 1.4-1.4-5.6-5.6z"/></svg>',
              'code-sample': '<svg width="24" height="26"><path d="M7.1 11a2.8 2.8 0 0 1-.8 2 2.8 2.8 0 0 1 .8 2v1.7c0 .3.1.6.4.8.2.3.5.4.8.4.3 0 .4.2.4.4v.8c0 .2-.1.4-.4.4-.7 0-1.4-.3-2-.8-.5-.6-.8-1.3-.8-2V15c0-.3-.1-.6-.4-.8-.2-.3-.5-.4-.8-.4a.4.4 0 0 1-.4-.4v-.8c0-.2.2-.4.4-.4.3 0 .6-.1.8-.4.3-.2.4-.5.4-.8V9.3c0-.7.3-1.4.8-2 .6-.5 1.3-.8 2-.8.3 0 .4.2.4.4v.8c0 .2-.1.4-.4.4-.3 0-.6.1-.8.4-.3.2-.4.5-.4.8V11zm9.8 0V9.3c0-.3-.1-.6-.4-.8-.2-.3-.5-.4-.8-.4a.4.4 0 0 1-.4-.4V7c0-.2.1-.4.4-.4.7 0 1.4.3 2 .8.5.6.8 1.3.8 2V11c0 .3.1.6.4.8.2.3.5.4.8.4.2 0 .4.2.4.4v.8c0 .2-.2.4-.4.4-.3 0-.6.1-.8.4-.3.2-.4.5-.4.8v1.7c0 .7-.3 1.4-.8 2-.6.5-1.3.8-2 .8a.4.4 0 0 1-.4-.4v-.8c0-.2.1-.4.4-.4.3 0 .6-.1.8-.4.3-.2.4-.5.4-.8V15a2.8 2.8 0 0 1 .8-2 2.8 2.8 0 0 1-.8-2zm-3.3-.4c0 .4-.1.8-.5 1.1-.3.3-.7.5-1.1.5-.4 0-.8-.2-1.1-.5-.4-.3-.5-.7-.5-1.1 0-.5.1-.9.5-1.2.3-.3.7-.4 1.1-.4.4 0 .8.1 1.1.4.4.3.5.7.5 1.2zM12 13c.4 0 .8.1 1.1.5.4.3.5.7.5 1.1 0 1-.1 1.6-.5 2a3 3 0 0 1-1.1 1c-.4.3-.8.4-1.1.4a.5.5 0 0 1-.5-.5V17a3 3 0 0 0 1-.2l.6-.6c-.6 0-1-.2-1.3-.5-.2-.3-.3-.7-.3-1 0-.5.1-1 .5-1.2.3-.4.7-.5 1.1-.5z" fill-rule="evenodd"/></svg>',
              'color-levels': '<svg width="24" height="24"><path fill-rule="nonzero" d="M17.7 8L12 2.2 6.3 7.9a8 8 0 1 0 11.4 0zM12 19.5a6 6 0 0 1-4.2-1.8 6 6 0 0 1 0-8.5L12 5.1v14.5z"/></svg>',
              'color-picker': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 3a9 9 0 0 0 0 18 1.5 1.5 0 0 0 1.1-2.5c-.2-.3-.4-.6-.4-1 0-.8.7-1.5 1.5-1.5H16a5 5 0 0 0 5-5c0-4.4-4-8-9-8zm-5.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm3-4a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm3 4a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/></svg>',
              'color-swatch-remove-color': '<svg width="24" height="24"><path stroke="#000" stroke-width="2" d="M21 3L3 21" fill-rule="evenodd"/></svg>',
              'color-swatch': '<svg width="24" height="24"><rect width="18" height="18" x="3" y="3" rx="1" fill-rule="evenodd"/></svg>',
              'comment': '<svg width="24" height="24"><path fill-rule="nonzero" d="M20 6c0-.5-.4-1-1-1H5a1 1 0 0 0-1 1v11c0 .6.5 1 1 1h11.5l3.5 3V6z"/></svg>',
              'contrast': '<svg width="24" height="24"><path d="M12 4a7.8 7.8 0 0 1 5.7 2.3A8 8 0 1 1 12 4zm-6 8a6 6 0 0 0 6 6V6a6 6 0 0 0-6 6z" fill-rule="evenodd"/></svg>',
              'copy': '<svg width="24" height="24"><path fill-rule="nonzero" d="M17 2H5a2 2 0 0 0-2 2v13h2V4h12V2zm3 3.8a2 2 0 0 1 2 2V21c0 1-.9 1.9-2 1.9H9a2 2 0 0 1-2-2V7.8c0-1 .9-1.9 2-1.9h11zm0 15.3V7.7H9v13.4h11z"/></svg>',
              'crop': '<svg width="24" height="24"><path fill-rule="nonzero" d="M17 15h2V7a2 2 0 0 0-2-2H9v2h8v8zM7 17V1H5v4H1v2h4v10c0 1.1.9 2 2 2h10v4h2v-4h4v-2H7z"/></svg>',
              'cut': '<svg width="24" height="24"><path fill-rule="nonzero" d="M9.6 7.6c.3-.5.4-1 .4-1.6a4 4 0 1 0-2.4 3.6L10 12l-2.4 2.4c-.5-.3-1-.4-1.6-.4a4 4 0 1 0 3.6 2.4L12 14l7 7h3v-1L9.6 7.6zM6 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6-7.5a.5.5 0 0 1-.5-.5c0-.3.2-.5.5-.5s.5.2.5.5-.2.5-.5.5zM19 3l-6 6 2 2 7-7V3h-3z"/></svg>',
              'document-properties': '<svg width="24" height="24"><path fill-rule="nonzero" d="M7 4a1 1 0 0 0-1 1v14c0 .6.4 1 1 1h10c.6 0 1-.4 1-1V9l-5-5H7zm6 5V5l4 4h-4z"/></svg>',
              'drag': '<svg width="24" height="24"><path d="M13 5h2v2h-2V5zm0 4h2v2h-2V9zM9 9h2v2H9V9zm4 4h2v2h-2v-2zm-4 0h2v2H9v-2zm0 4h2v2H9v-2zm4 0h2v2h-2v-2zM9 5h2v2H9V5z" fill-rule="evenodd"/></svg>',
              'duplicate': '<svg width="24" height="24"><path fill-rule="nonzero" d="M17 2H5a2 2 0 0 0-2 2v13h2V4h12V2zm3 3.8a2 2 0 0 1 2 2V21c0 1-.9 1.9-2 1.9H9a2 2 0 0 1-2-2V7.8c0-1 .9-1.9 2-1.9h11zm0 15.3V7.7H9v13.4h11zM17 16h1a1 1 0 0 1 0 2h-1v1a1 1 0 0 1-2 0v-1h-1a1 1 0 0 1 0-2h1v-1a1 1 0 0 1 2 0v1z"/></svg>',
              'edit-image': '<svg width="24" height="24"><path fill-rule="nonzero" d="M7.5 21.5a10.5 10.5 0 0 1-6-8.5H0a12 12 0 0 0 12 11h.6L8.8 20l-1.3 1.4zM12 0h-.7L15.2 4l1.3-1.4c3.3 1.6 5.6 4.7 6 8.5H24A12 12 0 0 0 12 0zM16 14h2V8a2 2 0 0 0-2-2h-6v2h6v6zm-8 2V4H6v2H4v2h2v8c0 1.1.9 2 2 2h8v2h2v-2h2v-2H8z"/></svg>',
              'embed-page': '<svg width="24" height="24"><path fill-rule="nonzero" d="M15.5 20a11.7 11.7 0 0 1 .2-3H20v1.2c0 1-.8 1.8-1.8 1.8h-2.7zm-2 0h-3.4a7.6 7.6 0 0 1 .2-3h3.3a16.5 16.5 0 0 0 0 3zM8 20H5.8c-1 0-1.8-.8-1.8-1.8V5.8C4 4.8 4.8 4 5.8 4h12.4c1 0 1.8.8 1.8 1.8v3.4A10 10 0 0 0 8 20zm10-9a14 14 0 0 1 2 4h-4a14 14 0 0 1 2-4zm-4 4h-3a8 8 0 0 1 4.4-3.6c-.6 1.2-1 2.3-1.4 3.6z"/></svg>',
              'embed': '<svg width="24" height="24"><path fill-rule="nonzero" d="M20 18.2c0 1-.8 1.8-1.8 1.8H5.8c-1 0-1.8-.8-1.8-1.8V5.8C4 4.8 4.8 4 5.8 4h12.4c1 0 1.8.8 1.8 1.8v12.4zM9.8 7.6A.5.5 0 0 0 9 8v8a.5.5 0 0 0 .8.4l5.6-4a.5.5 0 0 0 0-.8l-5.6-4z"/></svg>',
              'emoji': '<svg width="24" height="24"><path fill-rule="nonzero" d="M9 11c.6 0 1-.4 1-1s-.4-1-1-1a1 1 0 0 0-1 1c0 .6.4 1 1 1zm6 0c.6 0 1-.4 1-1s-.4-1-1-1a1 1 0 0 0-1 1c0 .6.4 1 1 1zm-3 5.5c2.1 0 4-1.5 4.4-3.5H7.6c.5 2 2.3 3.5 4.4 3.5zM12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 14.5a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13z"/></svg>',
              'fill': '<svg width="24" height="26"><path fill-rule="nonzero" d="M16.6 12l-9-9-1.4 1.4 2.4 2.4-5.2 5.1c-.5.6-.5 1.6 0 2.2L9 19.6a1.5 1.5 0 0 0 2.2 0l5.5-5.5c.5-.6.5-1.6 0-2.2zM5.2 13L10 8.2l4.8 4.8H5.2zM19 14.5s-2 2.2-2 3.5c0 1.1.9 2 2 2a2 2 0 0 0 2-2c0-1.3-2-3.5-2-3.5z"/></svg>',
              'flip-horizontally': '<svg width="24" height="24"><path fill-rule="nonzero" d="M15 21h2v-2h-2v2zm4-12h2V7h-2v2zM3 5v14c0 1.1.9 2 2 2h4v-2H5V5h4V3H5a2 2 0 0 0-2 2zm16-2v2h2a2 2 0 0 0-2-2zm-8 20h2V1h-2v22zm8-6h2v-2h-2v2zM15 5h2V3h-2v2zm4 8h2v-2h-2v2zm0 8a2 2 0 0 0 2-2h-2v2z"/></svg>',
              'flip-vertically': '<svg width="24" height="24"><path fill-rule="nonzero" d="M3 15h2v2H3v-2zm12 4h2v2h-2v-2zm4-16a2 2 0 0 1 2 2v4h-2V5H5v4H3V5c0-1.1.9-2 2-2h14zm2 16a2 2 0 0 1-2 2v-2h2zM1 11h22v2H1v-2zm6 8h2v2H7v-2zm12-4h2v2h-2v-2zm-8 4h2v2h-2v-2zm-8 0h2v2a2 2 0 0 1-2-2z"/></svg>',
              'format-painter': '<svg width="24" height="24"><path fill-rule="nonzero" d="M16 6c0-.5-.4-1-1-1H7a1 1 0 0 0-1 1v3c0 .6.5 1 1 1h8c.6 0 1-.4 1-1V8h1.5v3H10v8h2v-6.5h7V7h-3V6z"/></svg>',
              'fullscreen': '<svg width="24" height="24"><path fill-rule="nonzero" d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/></svg>',
              'gamma': '<svg width="24" height="24"><path fill-rule="nonzero" d="M4 3h16c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1V4c0-.6.4-1 1-1zm1 2v14h14V5H5zm6.5 11.8V14L9.2 8.7a5.1 5.1 0 0 0-.4-.8l-.1-.2H8 8v-1l.3-.1.3-.1h.7a1 1 0 0 1 .6.5l.1.3a8.5 8.5 0 0 1 .3.6l1.9 4.6 2-5.2a1 1 0 0 1 1-.6.5.5 0 0 1 .5.6L13 14v2.8a.7.7 0 0 1-1.4 0z"/></svg>',
              'help': '<svg width="24" height="24"><path fill-rule="nonzero" d="M11 18h2v-2h-2v2zm1-16a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-14a4 4 0 0 0-4 4h2c0-1.1.9-2 2-2a2 2 0 0 1 2 2c0 2-3 1.8-3 5h2c0-2.3 3-2.5 3-5a4 4 0 0 0-4-4z"/></svg>',
              'highlight-bg-color': '<svg width="24" height="24"><g fill-rule="nonzero"><path id="tox-icon-highlight-bg-color__color" d="M3 18h18v3H3z"/><path d="M7.7 16.7H3l3.3-3.3-.7-.8L10.2 8l4 4.1-4 4.2c-.2.2-.6.2-.8 0l-.6-.7-1.1 1.1zm5-7.5L11 7.4l3-2.9a2 2 0 0 1 2.6 0L18 6c.7.7.7 2 0 2.7l-2.9 2.9-1.8-1.8-.5-.6"/></g></svg>',
              'home': '<svg width="24" height="24"><path fill-rule="nonzero" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/></svg>',
              'horizontal-rule': '<svg width="24" height="24"><path d="M4 11h16v2H4z" fill-rule="evenodd"/></svg>',
              'image-options': '<svg width="24" height="24"><path fill-rule="nonzero" d="M6 10a2 2 0 0 0-2 2c0 1.1.9 2 2 2a2 2 0 0 0 2-2 2 2 0 0 0-2-2zm12 0a2 2 0 0 0-2 2c0 1.1.9 2 2 2a2 2 0 0 0 2-2 2 2 0 0 0-2-2zm-6 0a2 2 0 0 0-2 2c0 1.1.9 2 2 2a2 2 0 0 0 2-2 2 2 0 0 0-2-2z"/></svg>',
              'image': '<svg width="24" height="24"><path fill-rule="nonzero" d="M19 4H5a1 1 0 0 0-1 1v14c0 .6.5 1 1 1h14c.6 0 1-.4 1-1V5c0-.5-.4-1-1-1zM6.5 16l2.8-3.5 2 2.3 2.7-3.5 3.5 4.7h-11z"/></svg>',
              'indent': '<svg width="24" height="24"><path fill-rule="nonzero" d="M11 15h8v-2h-8v2zm-2.5-3L5 8.5v7L8.5 12zM5 19h14v-2H5v2zM5 5v2h14V5H5zm6 6h8V9h-8v2z"/></svg>',
              'indeterminate': '<svg width="24" height="24"><path fill-rule="nonzero" d="M19 3H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-2 10H7v-2h10v2z"/></svg>',
              'info': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg>',
              'insert-character': '<svg width="24" height="24"><path d="M15 18h4l1-2v4h-6v-3.3l1.4-1a6 6 0 0 0 1.8-2.9 6.3 6.3 0 0 0-.1-4.1 5.8 5.8 0 0 0-3-3.2c-.6-.3-1.3-.5-2.1-.5a5.1 5.1 0 0 0-3.9 1.8 6.3 6.3 0 0 0-1.3 6 6.2 6.2 0 0 0 1.8 3l1.4.9V20H4v-4l1 2h4v-.5l-2-1L5.4 15A6.5 6.5 0 0 1 4 11c0-1 .2-1.9.6-2.7A7 7 0 0 1 6.3 6C7.1 5.4 8 5 9 4.5c1-.3 2-.5 3.1-.5a8.8 8.8 0 0 1 5.7 2 7 7 0 0 1 1.7 2.3 6 6 0 0 1 .2 4.8c-.2.7-.6 1.3-1 1.9a7.6 7.6 0 0 1-3.6 2.5v.5z" fill-rule="evenodd"/></svg>',
              'insert-time': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm.5-13v5.3l4.5 2.6-.8 1.2L11 13V7h1.5z"/></svg>',
              'invert': '<svg width="24" height="24"><path fill-rule="nonzero" d="M20.6 20.9l-2.3-2.4-6.3-6.3-3.6-3.5L7 7.3 4.3 4.5 3 5.8l2.8 2.8A8 8 0 0 0 17 19.8l2.7 2.7 1.3-1.3-.4-.3zM12 19.6a6 6 0 0 1-4.2-1.8 6 6 0 0 1-.6-7.8l4.8 4.8v4.8zM12 5v4.6l7.3 7.2a8 8 0 0 0-1.7-9L12 2.3 8.3 6l1.4 1.4L12 5z"/></svg>',
              'italic': '<svg width="24" height="24"><path fill-rule="nonzero" d="M10 6v2h2.6l-3.7 8H6v2h8v-2h-2.6l3.7-8H18V6z"/></svg>',
              'line': '<svg width="24" height="24"><path d="M15.5 9.2L7.7 17H4.8v-2.8l7.9-7.9 2.8 2.9zm1-1l-2.8-2.9 1-1 .6-.3.6.3L17.6 6l.3.6-.3.6-1 1zm-11.8 10h14.6V20H4.7v-1.8z" fill-rule="evenodd"/></svg>',
              'link': '<svg width="24" height="24"><path fill-rule="nonzero" d="M4.9 12c0-1.2 1-2.1 2.1-2.1h4V8H7a4 4 0 1 0 0 8h4v-1.9H7c-1.2 0-2.1-1-2.1-2.1zM17 8h-4v1.9h4a2.1 2.1 0 1 1 0 4.2h-4V16h4a4 4 0 1 0 0-8zm-8 5h6v-2H9v2z"/></svg>',
              'list-bull-circle': '<svg width="48" height="48"><g fill-rule="evenodd"><path fill-rule="nonzero" d="M11 16a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM11 26a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM11 36a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/><path opacity=".2" d="M18 12h22v4H18zM18 22h22v4H18zM18 32h22v4H18z"/></g></svg>',
              'list-bull-default': '<svg width="48" height="48"><g fill-rule="evenodd"><circle cx="11" cy="14" r="3"/><circle cx="11" cy="24" r="3"/><circle cx="11" cy="34" r="3"/><path opacity=".2" d="M18 12h22v4H18zM18 22h22v4H18zM18 32h22v4H18z"/></g></svg>',
              'list-bull-square': '<svg width="48" height="48"><g fill-rule="evenodd"><path d="M8 11h6v6H8zM8 21h6v6H8zM8 31h6v6H8z"/><path opacity=".2" d="M18 12h22v4H18zM18 22h22v4H18zM18 32h22v4H18z"/></g></svg>',
              'list-num-default': '<svg width="48" height="48"><g fill-rule="evenodd"><path opacity=".2" d="M18 12h22v4H18zM18 22h22v4H18zM18 32h22v4H18z"/><path d="M10 17v-4.8l-1.5 1v-1.1l1.6-1h1.2V17h-1.2zm3.6.1c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7.5 0 .7.3.7.7 0 .4-.2.7-.7.7zm-5 5.7c0-1.2.8-2 2.1-2s2.1.8 2.1 1.8c0 .7-.3 1.2-1.4 2.2l-1.1 1v.2h2.6v1H8.6v-.9l2-1.9c.8-.8 1-1.1 1-1.5 0-.5-.4-.8-1-.8-.5 0-.9.3-.9.9H8.5zm6.3 4.3c-.5 0-.7-.3-.7-.7 0-.4.2-.7.7-.7.4 0 .7.3.7.7 0 .4-.3.7-.7.7zM10 34.4v-1h.7c.6 0 1-.3 1-.8 0-.4-.4-.7-1-.7s-1 .3-1 .8H8.6c0-1.1 1-1.8 2.2-1.8 1.3 0 2.1.6 2.1 1.6 0 .7-.4 1.2-1 1.3v.1c.8.1 1.3.7 1.3 1.4 0 1-1 1.9-2.4 1.9-1.3 0-2.2-.8-2.3-2h1.2c0 .6.5 1 1.1 1 .7 0 1-.4 1-1 0-.5-.3-.8-1-.8h-.7zm4.7 2.7c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7.5 0 .8.3.8.7 0 .4-.3.7-.8.7z"/></g></svg>',
              'list-num-lower-alpha': '<svg width="48" height="48"><g fill-rule="evenodd"><path opacity=".2" d="M18 12h22v4H18zM18 22h22v4H18zM18 32h22v4H18z"/><path d="M10.3 15.2c.5 0 1-.4 1-.9V14h-1c-.5.1-.8.3-.8.6 0 .4.3.6.8.6zm-.4.9c-1 0-1.5-.6-1.5-1.4 0-.8.6-1.3 1.7-1.4h1.1v-.4c0-.4-.2-.6-.7-.6-.5 0-.8.1-.9.4h-1c0-.8.8-1.4 2-1.4 1.1 0 1.8.6 1.8 1.6V16h-1.1v-.6h-.1c-.2.4-.7.7-1.3.7zm4.6 0c-.5 0-.7-.3-.7-.7 0-.4.2-.7.7-.7.4 0 .7.3.7.7 0 .4-.3.7-.7.7zm-3.2 10c-.6 0-1.2-.3-1.4-.8v.7H8.5v-6.3H10v2.5c.3-.5.8-.9 1.4-.9 1.2 0 1.9 1 1.9 2.4 0 1.5-.7 2.4-1.9 2.4zm-.4-3.7c-.7 0-1 .5-1 1.3s.3 1.4 1 1.4c.6 0 1-.6 1-1.4 0-.8-.4-1.3-1-1.3zm4 3.7c-.5 0-.7-.3-.7-.7 0-.4.2-.7.7-.7.4 0 .7.3.7.7 0 .4-.3.7-.7.7zm-2.2 7h-1.2c0-.5-.4-.8-.9-.8-.6 0-1 .5-1 1.4 0 1 .4 1.4 1 1.4.5 0 .8-.2 1-.7h1c0 1-.8 1.7-2 1.7-1.4 0-2.2-.9-2.2-2.4s.8-2.4 2.2-2.4c1.2 0 2 .7 2 1.7zm1.8 3c-.5 0-.8-.3-.8-.7 0-.4.3-.7.8-.7.4 0 .7.3.7.7 0 .4-.3.7-.7.7z"/></g></svg>',
              'list-num-lower-greek': '<svg width="48" height="48"><g fill-rule="evenodd"><path opacity=".2" d="M18 12h22v4H18zM18 22h22v4H18zM18 32h22v4H18z"/><path d="M10.5 15c.7 0 1-.5 1-1.3s-.3-1.3-1-1.3c-.5 0-.9.5-.9 1.3s.4 1.4 1 1.4zm-.3 1c-1.1 0-1.8-.8-1.8-2.3 0-1.5.7-2.4 1.8-2.4.7 0 1.1.4 1.3 1h.1v-.9h1.2v3.2c0 .4.1.5.4.5h.2v.9h-.6c-.6 0-1-.2-1.1-.7h-.1c-.2.4-.7.8-1.4.8zm5 .1c-.5 0-.8-.3-.8-.7 0-.4.3-.7.7-.7.5 0 .8.3.8.7 0 .4-.3.7-.8.7zm-4.9 7v-1h.3c.6 0 1-.2 1-.7 0-.5-.4-.8-1-.8-.5 0-.8.3-.8 1v2.2c0 .8.4 1.3 1.1 1.3.6 0 1-.4 1-1s-.5-1-1.3-1h-.3zM8.6 22c0-1.5.7-2.3 2-2.3 1.2 0 2 .6 2 1.6 0 .6-.3 1-.8 1.3.8.3 1.3.8 1.3 1.7 0 1.2-.8 1.9-1.9 1.9-.6 0-1.1-.3-1.3-.8v2.2H8.5V22zm6.2 4.2c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7.5 0 .7.3.7.7 0 .4-.2.7-.7.7zm-4.5 8.5L8 30h1.4l1.7 3.5 1.7-3.5h1.1l-2.2 4.6v.1c.5.8.7 1.4.7 1.8 0 .4-.1.8-.4 1-.2.2-.6.3-1 .3-.9 0-1.3-.4-1.3-1.2 0-.5.2-1 .5-1.7l.1-.2zm.7 1a2 2 0 0 0-.4.9c0 .3.1.4.4.4.3 0 .4-.1.4-.4 0-.2-.1-.6-.4-1zm4.5.5c-.5 0-.8-.3-.8-.7 0-.4.3-.7.8-.7.4 0 .7.3.7.7 0 .4-.3.7-.7.7z"/></g></svg>',
              'list-num-lower-roman': '<svg width="48" height="48"><g fill-rule="evenodd"><path opacity=".2" d="M18 12h22v4H18zM18 22h22v4H18zM18 32h22v4H18z"/><path d="M15.1 16v-1.2h1.3V16H15zm0 10v-1.2h1.3V26H15zm0 10v-1.2h1.3V36H15z"/><path fill-rule="nonzero" d="M12 21h1.5v5H12zM12 31h1.5v5H12zM9 21h1.5v5H9zM9 31h1.5v5H9zM6 31h1.5v5H6zM12 11h1.5v5H12zM12 19h1.5v1H12zM12 29h1.5v1H12zM9 19h1.5v1H9zM9 29h1.5v1H9zM6 29h1.5v1H6zM12 9h1.5v1H12z"/></g></svg>',
              'list-num-upper-alpha': '<svg width="48" height="48"><g fill-rule="evenodd"><path opacity=".2" d="M18 12h22v4H18zM18 22h22v4H18zM18 32h22v4H18z"/><path d="M12.6 17l-.5-1.4h-2L9.5 17H8.3l2-6H12l2 6h-1.3zM11 12.3l-.7 2.3h1.6l-.8-2.3zm4.7 4.8c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7.5 0 .7.3.7.7 0 .4-.2.7-.7.7zM11.4 27H8.7v-6h2.6c1.2 0 1.9.6 1.9 1.5 0 .6-.5 1.2-1 1.3.7.1 1.3.7 1.3 1.5 0 1-.8 1.7-2 1.7zM10 22v1.5h1c.6 0 1-.3 1-.8 0-.4-.4-.7-1-.7h-1zm0 4H11c.7 0 1.1-.3 1.1-.8 0-.6-.4-.9-1.1-.9H10V26zm5.4 1.1c-.5 0-.8-.3-.8-.7 0-.4.3-.7.8-.7.4 0 .7.3.7.7 0 .4-.3.7-.7.7zm-4.1 10c-1.8 0-2.8-1.1-2.8-3.1s1-3.1 2.8-3.1c1.4 0 2.5.9 2.6 2.2h-1.3c0-.7-.6-1.1-1.3-1.1-1 0-1.6.7-1.6 2s.6 2 1.6 2c.7 0 1.2-.4 1.4-1h1.2c-.1 1.3-1.2 2.2-2.6 2.2zm4.5 0c-.5 0-.8-.3-.8-.7 0-.4.3-.7.8-.7.4 0 .7.3.7.7 0 .4-.3.7-.7.7z"/></g></svg>',
              'list-num-upper-roman': '<svg width="48" height="48"><g fill-rule="evenodd"><path opacity=".2" d="M18 12h22v4H18zM18 22h22v4H18zM18 32h22v4H18z"/><path d="M15.1 17v-1.2h1.3V17H15zm0 10v-1.2h1.3V27H15zm0 10v-1.2h1.3V37H15z"/><path fill-rule="nonzero" d="M12 20h1.5v7H12zM12 30h1.5v7H12zM9 20h1.5v7H9zM9 30h1.5v7H9zM6 30h1.5v7H6zM12 10h1.5v7H12z"/></g></svg>',
              'lock': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 17a2 2 0 0 0 2-2 2 2 0 0 0-2-2 2 2 0 0 0-2 2c0 1.1.9 2 2 2zm6-9h-1V6A5 5 0 0 0 7 6v2H6a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2zM8.9 6a3.1 3.1 0 0 1 6.2 0v2H8.9V6zM18 20H6V10h12v10z"/></svg>',
              'ltr': '<svg width="24" height="24"><path fill-rule="nonzero" d="M9 10v5h2V4h2v11h2V4h2V2H9a4 4 0 1 0 0 8zm12 8l-4-4v3H5v2h12v3l4-4z"/></svg>',
              'more-drawer': '<svg width="24" height="24"><path fill-rule="nonzero" d="M6 10a2 2 0 0 0-2 2c0 1.1.9 2 2 2a2 2 0 0 0 2-2 2 2 0 0 0-2-2zm12 0a2 2 0 0 0-2 2c0 1.1.9 2 2 2a2 2 0 0 0 2-2 2 2 0 0 0-2-2zm-6 0a2 2 0 0 0-2 2c0 1.1.9 2 2 2a2 2 0 0 0 2-2 2 2 0 0 0-2-2z"/></svg>',
              'new-document': '<svg width="24" height="24"><path fill-rule="nonzero" d="M7 4a1 1 0 0 0-1 1v14c0 .6.4 1 1 1h10c.6 0 1-.4 1-1V9l-5-5H7zm6 5V5l4 4h-4z"/></svg>',
              'new-tab': '<svg width="24" height="24"><path d="M15 13l2-2v8H5V7h8l-2 2H7v8h8v-4zm4-8v5.5l-2-2-5.6 5.5H10v-1.4L15.5 7l-2-2H19z" fill-rule="evenodd"/></svg>',
              'non-breaking': '<svg width="24" height="24"><path d="M11 11H7V9h4V5h2v4h4v2h-4v4h-2v-4zm10 3v6H3v-6h2v4h14v-4h2z" fill-rule="evenodd"/></svg>',
              'notice': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg>',
              'ordered-list': '<svg width="24" height="24"><path fill-rule="nonzero" d="M5 16h2v.5H6v1h1v.5H5v1h3v-4H5v1zm0-5h1.8L5 13.1v.9h3v-1H6.2L8 10.9V10H5v1zm1-2h1V5H5v1h1v3zm4-3v2h9V6h-9zm0 12h9v-2h-9v2zm0-5h9v-2h-9v2z"/></svg>',
              'orientation': '<svg width="24" height="24"><path fill-rule="nonzero" d="M7.3 6.4L1 13l6.4 6.5 6.5-6.5-6.5-6.5zM3.7 13l3.6-3.7L11 13l-3.7 3.7-3.6-3.7zm15.7-6.3A9 9 0 0 0 13 4V.8L8.8 5 13 9.2V6a7 7 0 1 1-2.8 13.4l-1.5 1.5A9 9 0 0 0 19.4 6.6z"/></svg>',
              'outdent': '<svg width="24" height="24"><path fill-rule="nonzero" d="M11 15h8v-2h-8v2zm0-4h8V9h-8v2zm8 6H5v2h14v-2zM5 12l3.5 3.5v-7L5 12zm0-7v2h14V5H5z"/></svg>',
              'page-break': '<svg width="24" height="24"><g fill-rule="evenodd"><path d="M4 11h2v2H4v-2zm3 0h3v2H7v-2zm4 0h2v2h-2v-2zm3 0h3v2h-3v-2zm4 0h2v2h-2v-2zM7 2v6h10V2h2v8H5V2zM7 22H5v-8h14v8h-2v-6H7z"/></g></svg>',
              'paragraph': '<svg width="24" height="24"><path fill-rule="nonzero" d="M10 12v8h2V6h2v14h2V6h2V4h-8a4 4 0 1 0 0 8z"/></svg>',
              'paste-text': '<svg width="24" height="24"><path fill-rule="nonzero" d="M19 2a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h4.2A3 3 0 0 1 12 0a3 3 0 0 1 2.8 2H19zm-7 0a1 1 0 0 0-1 1c0 .5.4 1 1 1s1-.5 1-1-.4-1-1-1zm7 18V4h-2v3H7V4H5v16h14zM9 11h6v2h-.5l-.5-1h-1v4h.8v1h-3.6v-1h.8v-4h-1l-.5 1H9v-2z"/></svg>',
              'paste': '<svg width="24" height="24"><path fill-rule="nonzero" d="M19 2h-4.2A3 3 0 0 0 12 0a3 3 0 0 0-2.8 2H5a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm-7 0c.6 0 1 .5 1 1s-.4 1-1 1a1 1 0 0 1-1-1c0-.5.4-1 1-1zm7 18H5V4h2v3h10V4h2v16z"/></svg>',
              'permanent-pen': '<svg width="24" height="24"><path fill-rule="nonzero" d="M10.5 17.5L8 20H3v-3l3.5-3.5a2 2 0 0 1 0-3L14 3l1 1-7.3 7.3a1 1 0 0 0 0 1.4l3.6 3.6c.4.4 1 .4 1.4 0L20 9l1 1-7.6 7.6a2 2 0 0 1-2.8 0l-.1-.1z"/></svg>',
              'plus': '<svg width="24" height="24"><path fill-rule="nonzero" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z"/></svg>',
              'preferences': '<svg width="24" height="24"><path fill-rule="nonzero" d="M19.4 13a7.8 7.8 0 0 0 0-2l2.1-1.6c.2-.2.3-.5.2-.7l-2-3.4c-.2-.3-.4-.3-.6-.3l-2.5 1-1.7-1-.4-2.6c0-.2-.3-.4-.5-.4h-4c-.3 0-.5.2-.5.4l-.4 2.7c-.6.2-1.1.6-1.7 1L5 5c-.2-.1-.4 0-.6.2l-2 3.4c0 .3 0 .5.2.7l2 1.6a8 8 0 0 0 0 2l-2 1.6c-.2.2-.3.5-.2.7l2 3.4c.2.3.4.3.7.3l2.4-1 1.7 1 .4 2.6c0 .2.3.4.5.4h4c.3 0 .5-.2.5-.4l.4-2.7c.6-.2 1.1-.6 1.7-1l2.4 1c.3.1.5 0 .7-.2l2-3.4c0-.3 0-.5-.2-.7l-2-1.6zM12 15.5a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7z"/></svg>',
              'preview': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 4.5C7 4.5 2.7 7.6 1 12a11.8 11.8 0 0 0 22 0c-1.7-4.4-6-7.5-11-7.5zM12 17a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm0-8a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"/></svg>',
              'print': '<svg width="24" height="24"><path fill-rule="nonzero" d="M19 8H5a3 3 0 0 0-3 3v6h4v4h12v-4h4v-6a3 3 0 0 0-3-3zm-3 11H8v-5h8v5zm3-7a1 1 0 0 1-1-1c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1zm-1-9H6v4h12V3z"/></svg>',
              'quote': '<svg width="24" height="24"><path fill-rule="nonzero" d="M13 8v5h2.8L14 16h2.3l1.7-3V8h-5zm-7 5h2.8L7 16h2.3l1.7-3V8H6v5z"/></svg>',
              'redo': '<svg width="24" height="24"><path fill-rule="nonzero" d="M18.4 10.6a10.5 10.5 0 0 0-16.9 4.6L4 16a8 8 0 0 1 12.7-3.6L13 16h9V7l-3.6 3.6z"/></svg>',
              'reload': '<svg width="24" height="24"><path fill-rule="nonzero" d="M17.6 6.3A8 8 0 0 0 4 12a8 8 0 0 0 15.7 2h-2a6 6 0 1 1-1.5-6.2L13 11h7V4l-2.4 2.3z"/></svg>',
              'remove-formatting': '<svg width="24" height="24"><path fill-rule="nonzero" d="M5.3 7.5l5.1 5.2L8 18h2.5l1.6-3.6 4.6 4.6 1.3-1.3L6.6 6.3 5.3 7.6zM8.8 6l2 2h1.8L12 9.2l1.7 1.7L15.1 8H19V6H8.8z"/></svg>',
              'remove': '<svg width="24" height="24"><path fill-rule="nonzero" d="M6 19c0 1.1.9 2 2 2h8a2 2 0 0 0 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>',
              'resize-handle': '<svg width="10" height="10"><g fill-rule="nonzero"><path d="M8.2 1.1l.7.8-7 7L1 8zM8.2 5.2l.7.6-3 3-.8-.7z"/></g></svg>',
              'resize': '<svg width="24" height="24"><path d="M4 5c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h6c.3 0 .5.1.7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7a1 1 0 0 1-.7.3H7.4L18 16.6V13c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7v6c0 .3-.1.5-.3.7a1 1 0 0 1-.7.3h-6a1 1 0 0 1-.7-.3 1 1 0 0 1-.3-.7c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h3.6L6 7.4V11c0 .3-.1.5-.3.7a1 1 0 0 1-.7.3 1 1 0 0 1-.7-.3A1 1 0 0 1 4 11V5z" fill-rule="evenodd"/></svg>',
              'restore-draft': '<svg width="24" height="24"><path fill-rule="nonzero" d="M13 3c-5 0-9 4-9 9H1l3.9 3.9v.1L9 12H6a7 7 0 1 1 2 5l-1.4 1.4A9 9 0 1 0 13 3zm-1 5v5l4.3 2.5.7-1.2-3.5-2V8H12z"/></svg>',
              'rotate-left': '<svg width="24" height="24"><path fill-rule="nonzero" d="M7.1 8.5L5.7 7.1A8 8 0 0 0 4.1 11h2a6 6 0 0 1 1-2.5zm-1 4.5H4a8 8 0 0 0 1.6 3.9L7 15.5a6 6 0 0 1-1-2.5zm1 5.3A8 8 0 0 0 11 20v-2a6 6 0 0 1-2.5-1l-1.4 1.4zM13 4.1V1L8.4 5.5 13 10V6a6 6 0 0 1 0 12v2a8 8 0 0 0 0-16z"/></svg>',
              'rotate-right': '<svg width="24" height="24"><path fill-rule="nonzero" d="M15.6 5.5L11 1v3a8 8 0 0 0 0 16v-2a6 6 0 0 1 0-12v4l4.6-4.5zm4.3 5.5a8 8 0 0 0-1.6-3.9L17 8.5c.5.8.9 1.6 1 2.5h2zM13 17.9v2a8 8 0 0 0 3.9-1.6L15.5 17c-.8.5-1.6.9-2.5 1zm3.9-2.4l1.4 1.4A8 8 0 0 0 20 13h-2c-.1.9-.5 1.7-1 2.5z"/></svg>',
              'rtl': '<svg width="24" height="24"><path fill-rule="nonzero" d="M10 10v5h2V4h2v11h2V4h2V2h-8a4 4 0 1 0 0 8zm-2 7v-3l-4 4 4 4v-3h12v-2H8z"/></svg>',
              'save': '<svg width="24" height="24"><path fill-rule="nonzero" d="M17 3H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V7l-4-4zm-5 16a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm3-10H5V5h10v4z"/></svg>',
              'search': '<svg width="24" height="24"><path fill-rule="nonzero" d="M15.5 14h-.8l-.3-.3c1-1.1 1.6-2.6 1.6-4.2a6.5 6.5 0 1 0-2.3 5l.3.2v.8l5 5 1.5-1.5-5-5zm-6 0a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9z"/></svg>',
              'select-all': '<svg width="24" height="24"><path fill-rule="nonzero" d="M3 5h2V3a2 2 0 0 0-2 2zm0 8h2v-2H3v2zm4 8h2v-2H7v2zM3 9h2V7H3v2zm10-6h-2v2h2V3zm6 0v2h2a2 2 0 0 0-2-2zM5 21v-2H3c0 1.1.9 2 2 2zm-2-4h2v-2H3v2zM9 3H7v2h2V3zm2 18h2v-2h-2v2zm8-8h2v-2h-2v2zm0 8a2 2 0 0 0 2-2h-2v2zm0-12h2V7h-2v2zm0 8h2v-2h-2v2zm-4 4h2v-2h-2v2zm0-16h2V3h-2v2zM7 17h10V7H7v10zm2-8h6v6H9V9z"/></svg>',
              'selected': '<svg width="24" height="24"><path fill-rule="nonzero" d="M19 3H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-9 14l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z"/></svg>',
              'settings': '<svg width="24" height="24"><path fill-rule="nonzero" d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"/></svg>',
              'sharpen': '<svg width="24" height="24"><path d="M16 6l4 4-8 9-8-9 4-4h8zm-4 10.2l5.5-6.2-.1-.1H12v-.3h5.1l-.2-.2H12V9h4.6l-.2-.2H12v-.3h4.1l-.2-.2H12V8h3.6l-.2-.2H8.7L6.5 10l.1.1H12v.3H6.9l.2.2H12v.3H7.3l.2.2H12v.3H7.7l.3.2h4v.3H8.2l.2.2H12v.3H8.6l.3.2H12v.3H9l.3.2H12v.3H9.5l.2.2H12v.3h-2l.2.2H12v.3h-1.6l.2.2H12v.3h-1.1l.2.2h.9v.3h-.7l.2.2h.5v.3h-.3l.3.2z" fill-rule="evenodd"/></svg>',
              'sourcecode': '<svg width="24" height="24"><path fill-rule="nonzero" d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"/></svg>',
              'spell-check': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12.4 16h2.1l-5-13h-2l-5 13h2l1.2-3h5.6l1.1 3zm-6-5l2.1-5.5 2 5.5h-4zm15.2.6l-8.1 8L9.8 16l-1.4 1.4 5.1 5.1L23 13l-1.4-1.4z"/></svg>',
              'strike-through': '<svg width="24" height="24"><path fill-rule="nonzero" d="M11 18h2v-4h-2v4zM7 5v2h4v3h2V7h4V5H7zm-1 8h12v-2H6v2z"/></svg>',
              'subscript': '<svg width="24" height="24"><path fill-rule="nonzero" d="M10.4 10l4.6 4.6-1.4 1.4L9 11.4 4.4 16 3 14.6 7.6 10 3 5.4 4.4 4 9 8.6 13.6 4 15 5.4 10.4 10zM21 19h-5v-1l1-.8 1.7-1.6c.3-.4.5-.8.5-1.2 0-.3 0-.6-.2-.7-.2-.2-.5-.3-.9-.3a2 2 0 0 0-.8.2l-.7.3-.4-1.1 1-.6 1.2-.2c.8 0 1.4.3 1.8.7.4.4.6.9.6 1.5s-.2 1.1-.5 1.6a8 8 0 0 1-1.3 1.3l-.6.6h2.6V19z"/></svg>',
              'superscript': '<svg width="24" height="24"><path fill-rule="nonzero" d="M15 9.4L10.4 14l4.6 4.6-1.4 1.4L9 15.4 4.4 20 3 18.6 7.6 14 3 9.4 4.4 8 9 12.6 13.6 8 15 9.4zm5.9 1.6h-5v-1l1-.8 1.7-1.6c.3-.5.5-.9.5-1.3 0-.3 0-.5-.2-.7-.2-.2-.5-.3-.9-.3l-.8.2-.7.4-.4-1.2c.2-.2.5-.4 1-.5.3-.2.8-.2 1.2-.2.8 0 1.4.2 1.8.6.4.4.6 1 .6 1.6 0 .5-.2 1-.5 1.5l-1.3 1.4-.6.5h2.6V11z"/></svg>',
              'table-cell-properties': '<svg width="24" height="24"><path fill-rule="nonzero" d="M5 5v14h14V5H5zm6 6H7V7h4v4zm6 6h-4v-4h4v4zm0-6h-4V7h4v4z"/></svg>',
              'table-cell-select-all': '<svg width="24" height="24"><path fill-rule="nonzero" d="M11 19h2v-2h-2v2zm-3-6h2v-2H8v2zm0-6h2V5H8v2zm3 9h2v-2h-2v2zm-3 3h2v-2H8v2zm-3-9h2V8H5v2zm0 9h2v-2H5v2zM5 7h2V5H5v2zm0 6h2v-2H5v2zm6 0h2v-2h-2v2zm-6 3h2v-2H5v2zm12 0h2v-2h-2v2zm0 3h2v-2h-2v2zm0-6h2v-2h-2v2zm0-3h2V8h-2v2zm0-5v2h2V5h-2zm-6 2h2V5h-2v2zm3 0h2V5h-2v2zm-3 3h2V8h-2v2zm3 9h2v-2h-2v2zm0-6h2v-2h-2v2z"/></svg>',
              'table-cell-select-inner': '<svg width="24" height="24"><path fill-rule="nonzero" d="M5 19h2v-2H5v2zM7 8H5v2h2V8zm1 11h2v-2H8v2zm8-14h-2v2h2V5zM7 5H5v2h2V5zm3 0H8v2h2V5zM5 16h2v-2H5v2zm9 3h2v-2h-2v2zM13 5h-2v6H5v2h6v6h2v-6h6v-2h-6V5zm4 0v2h2V5h-2zm0 5h2V8h-2v2zm0 9h2v-2h-2v2zm0-3h2v-2h-2v2z"/></svg>',
              'table-delete-column': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 14.6l2.5-2.5 1.4 1.4-2.5 2.5 2.5 2.5-1.4 1.4-2.5-2.5L9.5 20l-1.4-1.4 2.5-2.5L8 13.5l1.4-1.4 2.5 2.5zM13 7v5h-2V7H7v11H5V5h14v13h-2V7h-4z"/></svg>',
              'table-delete-row': '<svg width="24" height="24"><path fill-rule="nonzero" d="M16 10.6L18.5 8l1.4 1.4-2.5 2.5 2.5 2.5-1.4 1.4-2.5-2.5-2.5 2.5-1.4-1.4 2.5-2.5L12 9.5l1.4-1.4 2.5 2.5zM7 11h5v2H7v4h11v2H5V5h13v2H7v4z"/></svg>',
              'table-delete-table': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 10.6L14.5 8l1.4 1.4-2.5 2.5 2.5 2.5-1.4 1.4-2.5-2.5L9.5 16l-1.4-1.4 2.5-2.5L8 9.5l1.4-1.4 2.5 2.5zM5 5h14v14H5V5zm2 2v10h10V7H7z"/></svg>',
              'table-insert-column-after': '<svg width="24" height="24"><path fill-rule="nonzero" d="M18 11h2v2h-2v2h-2v-2h-2v-2h2V9h2v2zm1 6v2H5V5h14v2h-6v10h6zm-8-6V7H7v4h4zm0 6v-4H7v4h4z"/></svg>',
              'table-insert-column-before': '<svg width="24" height="24"><g fill-rule="evenodd"><path fill-rule="nonzero" d="M5 17h6V7H5V5h14v14H5v-2zm8-6h4V7h-4v4zm0 6h4v-4h-4v4z"/><path d="M8 11h2v2H8v2H6v-2H4v-2h2V9h2z"/></g></svg>',
              'table-insert-row-above': '<svg width="24" height="24"><g fill-rule="evenodd"><path fill-rule="nonzero" d="M17 5h2v14H5V5h2v6h10V5zm-6 8H7v4h4v-4zm6 0h-4v4h4v-4z"/><path d="M13 6h2v2h-2v2h-2V8H9V6h2V4h2z"/></g></svg>',
              'table-insert-row-after': '<svg width="24" height="24"><g fill-rule="evenodd"><path fill-rule="nonzero" d="M17 19v-6H7v6H5V5h14v14h-2zm-6-8V7H7v4h4zm6 0V7h-4v4h4z"/><path d="M13 16h2v2h-2v2h-2v-2H9v-2h2v-2h2z"/></g></svg>',
              'table-left-header': '<svg width="24" height="24"><path fill-rule="nonzero" d="M5 5v14h14V5H5zm7 12H9v-4h3v4zm0-6H9V7h3v4zm5 6h-3v-4h3v4zm0-6h-3V7h3v4z"/></svg>',
              'table-merge-cells': '<svg width="24" height="24"><path fill-rule="nonzero" d="M5 5v14h14V5H5zm6 6H7V7h4v4zm6 6H7v-4h10v4zm0-6h-4V7h4v4z"/></svg>',
              'table-row-properties': '<svg width="24" height="24"><path fill-rule="nonzero" d="M5 5v14h14V5H5zm6 6H7V7h4v4zm6 0h-4V7h4v4z"/></svg>',
              'table-split-cells': '<svg width="24" height="24"><path fill-rule="nonzero" d="M17 11V7h-4v4h4zm0 2H7v4h1v2H5V5h14v14h-3v-2h1v-4zM7 11h4V7H7v4zm5 5l1.7-1.7 1 1-1.6 1.7 1.6 1.7-1 1-1.7-1.6-1.7 1.6-1-1 1.6-1.7-1.6-1.7 1-1 1.7 1.6z"/></svg>',
              'table-top-header': '<svg width="24" height="24"><path fill-rule="nonzero" d="M5 5v14h14V5H5zm6 12H7v-3h4v3zm0-5H7V9h4v3zm6 5h-4v-3h4v3zm0-5h-4V9h4v3z"/></svg>',
              'table': '<svg width="24" height="24"><path fill-rule="nonzero" d="M5 5v14h14V5H5zm6 12H7v-4h4v4zm0-6H7V7h4v4zm6 6h-4v-4h4v4zm0-6h-4V7h4v4z"/></svg>',
              'template': '<svg width="24" height="24"><path d="M19 19v-1H5v1h14zM9 16v-4a5 5 0 1 1 6 0v4h4a2 2 0 0 1 2 2v3H3v-3c0-1.1.9-2 2-2h4zm4 0v-5l.8-.6a3 3 0 1 0-3.6 0l.8.6v5h2z" fill-rule="nonzero"/></svg>',
              'temporary-placeholder': '<svg width="24" height="24"><path fill-rule="nonzero" d="M20.5 2.5c-.8 0-1.5.7-1.5 1.5a1.5 1.5 0 0 1-3 0 3 3 0 0 0-6 0v2H8.5c-.3 0-.5.2-.5.5v1a8 8 0 1 0 6 0v-1c0-.3-.2-.5-.5-.5H11V4a2 2 0 0 1 4 0 2.5 2.5 0 0 0 5 0c0-.3.2-.5.5-.5a.5.5 0 0 0 0-1zM8.1 10.9a5 5 0 0 0-1.2 7 .5.5 0 0 1-.8.5 6 6 0 0 1 1.5-8.3.5.5 0 1 1 .5.8z"/></svg>',
              'text-color': '<svg width="24" height="24"><g fill-rule="evenodd"><path id="tox-icon-text-color__color" d="M3 18h18v3H3z"/><path fill-rule="nonzero" d="M13 4h-2L6.5 16h2l1.1-3h4.8l1 3h2L13 4zm-2.6 7L12 6.7l1.6 4.3h-3.2z"/></g></svg>',
              'toc': '<svg width="24" height="24"><path fill-rule="nonzero" d="M3 9h14V7H3v2zm0 4h14v-2H3v2zm0 4h14v-2H3v2zm16 0h2v-2h-2v2zm0-10v2h2V7h-2zm0 6h2v-2h-2v2z"/></svg>',
              'translate': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12.9 15l-2.6-2.4c1.8-2 3-4.2 3.8-6.6H17V4h-7V2H8v2H1v2h11.2c-.7 2-1.8 3.8-3.2 5.3-1-1-1.7-2.1-2.3-3.3h-2c.7 1.6 1.7 3.2 3 4.6l-5.1 5L4 19l5-5 3.1 3.1.8-2zm5.6-5h-2L12 22h2l1.1-3H20l1.1 3h2l-4.5-12zm-2.6 7l1.6-4.3 1.6 4.3H16z"/></svg>',
              'underline': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 16a5 5 0 0 0 5-5V4h-2.5v7a2.5 2.5 0 0 1-5 0V4H7v7a5 5 0 0 0 5 5zm-6 2v2h12v-2H6z"/></svg>',
              'undo': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12.5 8c-2.7 0-5 1-6.9 2.6L2 7v9h9l-3.6-3.6A8 8 0 0 1 20 16l2.4-.8a10.5 10.5 0 0 0-10-7.2z"/></svg>',
              'unlink': '<svg width="24" height="24"><path fill-rule="nonzero" d="M17 7h-4v1.9h4a3.1 3.1 0 0 1 .8 6.1l1.4 1.4A5 5 0 0 0 17 7zm-1 4h-2.2l2 2h.2v-2zM2 4.3l3.1 3A5 5 0 0 0 7 17h4v-1.9H7a3.1 3.1 0 1 1-.3-6.2l2 2.1H8v2h2.7l2.3 2.3V17h1.7l4 4 1.3-1.3L3.3 3 2 4.3z"/></svg>',
              'unlock': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 17a2 2 0 0 0 2-2 2 2 0 0 0-2-2 2 2 0 0 0-2 2c0 1.1.9 2 2 2zm6-9h-1V6A5 5 0 0 0 7 6h1.9a3.1 3.1 0 0 1 6.2 0v2H6a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2zm0 12H6V10h12v10z"/></svg>',
              'unordered-list': '<svg width="24" height="24"><path fill-rule="nonzero" d="M10 13h9v-2h-9v2zm0-7v2h9V6h-9zm0 12h9v-2h-9v2zm-4-5h2v-2H6v2zm0-7v2h2V6H6zm0 12h2v-2H6v2z"/></svg>',
              'unselected': '<svg width="24" height="24"><path fill-rule="nonzero" d="M19 5v14H5V5h14zm0-2H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2z"/></svg>',
              'upload': '<svg width="24" height="24"><path fill-rule="nonzero" d="M9 16h6v-6h4l-7-7-7 7h4v6zm-4 2h14v2H5v-2z"/></svg>',
              'user': '<svg width="24" height="24"><path fill-rule="nonzero" d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 14.2A7.2 7.2 0 0 1 6 16c0-2 4-3.1 6-3.1s6 1 6 3a7.2 7.2 0 0 1-6 3.3z"/></svg>',
              'warning': '<svg width="24" height="24"><path fill-rule="nonzero" d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg>',
              'zoom-in': '<svg width="24" height="24"><path fill-rule="nonzero" d="M15.5 14l5 5-1.5 1.5-5-5v-.8l-.3-.3a6.5 6.5 0 1 1 .7-.7l.3.3h.8zm-6 0a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9zm2.5-4h-2v2H9v-2H7V9h2V7h1v2h2v1z"/></svg>',
              'zoom-out': '<svg width="24" height="24"><path fill-rule="nonzero" d="M15.5 14h-.8l-.3-.3c1-1.1 1.6-2.6 1.6-4.2a6.5 6.5 0 1 0-2.3 5l.3.2v.8l5 5 1.5-1.5-5-5zm-6 0a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zM7 9h5v1H7V9z"/></svg>',
              'library_add':'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z"/></svg>'
            }
          });
    }());
})();